
import { Component, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import BillboardView from '@/models/BillboardView'
import FormInput from '@/components/FormInput/FormInput.vue'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'
import Welcome from './layouts/Welcome.vue'
import Scoreboard from './layouts/Scoreboard.vue'
import Custom from './layouts/Custom.vue'

@Component({
  components: {
    Widget,
    FormInput,
    Welcome,
    Scoreboard,
    Custom,
  },
})
export default class Billboard extends ViewModel {
  public billboard_view: BillboardView = new BillboardView()

  public loading: boolean = true

  public ready: boolean = false

  public request_preset_before_asking_pin: boolean = true

  // 15 minutes
  public refresh_interval: number = 1000 * 60 * 15

  private channel: any = null

  public time = ''

  public updateTime() {
    this.time = moment().format('LT, dddd, MMMM Do YYYY')
  }

  public mounted() {
    if (
      this.billboard_view.fromLocalStorage
      && this.billboard_view.fromLocalStorage.pin
      && this.$route.params.code
    ) {
      this.billboard_view.pin = this.billboard_view.fromLocalStorage.pin
    }
    this.updateTime()
    setTimeout(() => {
      this.loadPresets()
    }, 1000)

    setInterval(() => {
      this.refreshData()
    }, this.refresh_interval)

    // Refresh page every 12 hours, force fetching UI updates
    setInterval(() => {
      window.location.reload()
    }, 1000 * 60 * 60 * 12)

    setInterval(() => {
      this.updateTime()
    }, 1000 * 10)
  }

  public get isAuth() {
    if (!this.billboard_view) return false
    return this.billboard_view.authenticated
  }

  public refreshData() {
    if (!this.billboard_view.authenticated) return
    this.billboard_view.loadPresets().catch(() => {
      WebMessage.error('Authentication failed, please re-enter your pin.')
      if (this.channel) {
        Vue.prototype.$echo
          .private(`billboard.${this.billboard_view.id}`)
          .stopListening('BillboardRefresh')
        Vue.prototype.$echo
          .private(`billboard.${this.billboard_view.id}`)
          .stopListening('BillboardReload')
        this.channel = null
      }
    })
  }

  public loadPresets() {
    if (this.loading && this.ready) return
    if (this.$route.params.code) this.billboard_view.code = this.$route.params.code

    if (!this.request_preset_before_asking_pin) {
      if (
        !this.billboard_view.pin
        && (!this.billboard_view.fromLocalStorage || !this.billboard_view.fromLocalStorage.pin)
      ) {
        return (this.loading = false)
      }
    } else if (!this.billboard_view.code || !this.billboard_view.pin) {
      this.loading = false
      return
    }

    this.loading = true

    this.billboard_view
      .checkAuth(this.request_preset_before_asking_pin)
      .then(response => {
        if (!this.$route.params.code) {
          this.$router.push({ name: 'billboard-view', params: { code: this.billboard_view.code } })
        }

        this.loading = false
        this.ready = true

        if (this.billboard_view.authenticated) {
          if (this.channel) {
            Vue.prototype.$echo
              .private(`billboard.${this.billboard_view.id}`)
              .stopListening('BillboardRefresh')
            Vue.prototype.$echo
              .private(`billboard.${this.billboard_view.id}`)
              .stopListening('BillboardReload')
            this.channel = null
          }

          setTimeout(() => {
            // Refresh on change
            this.channel = Vue.prototype.$echo
              .channel(`billboard.${this.billboard_view.id}`)
              .listen('BillboardRefresh', (e: any) => {
                this.refreshData()
              })
            // Forece window reload
            Vue.prototype.$echo
              .channel(`billboard.${this.billboard_view.id}`)
              .listen('BillboardReload', (e: any) => {
                window.location.reload()
              })
          }, 2000)
        }
      })
      .catch(error => {
        this.billboard_view.pin = null
        localStorage.removeItem('billboard_view')
        this.loading = false

        if (error.response.status === 429) {
          WebMessage.error(error.response.data.errors.email[0])
        } else {
          WebMessage.error('Authentication failed, please re-enter your pin.')
        }
      })
  }

  // for debug
  public formatData(data) {
    return JSON.stringify(data, null, 2)
      .replace(/</g, '&lt;') // Prevents HTML tag interpretation
      .replace(/>/g, '&gt;') // Prevents HTML tag interpretation
  }
}
