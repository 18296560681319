import Api from './Api'
import BillboardPreset from './BillboardPreset'
import Model from './interface/Model'
import WebMessage from './WebMessage'

export default class BillboardView extends Model {
  private is_authenticated: boolean = false

  public is_requesting: boolean = false

  public preset_not_found: boolean = false

  public code: any = null

  public pin: number | null = null

  public data: any = {}

  public get authenticated() {
    return this.is_authenticated
  }

  public get fromLocalStorage() {
    let keys: any = localStorage.getItem('billboard-view')
    return JSON.parse(keys)
  }

  public onError(error: any, retry: any = null) {
    if (error.response.data.includes('not found')) {
      this.preset_not_found = true
    }

    WebMessage.error(error.response.data)
    throw error
  }

  public async loadPresets() {
    this.is_requesting = true
    const api = new Api(false)
    api.catch_error = false
    return api.post('billboard-preview', {
      pin: this.pin,
      code: this.code,
    }, false).then(response => {
      if (response) {
        const data = response.data.result

        if (response.status === 200 && this.pin) {
          localStorage.setItem('billboard-view', JSON.stringify({
            pin: this.pin,
            code: this.code,
          }))
          this.data = BillboardPreset.toObject(data)
          this.preset_not_found = false
          this.id = data.billboard_id

          this.is_authenticated = true
        }

        return data
      }
    }).catch(error => {
      this.is_authenticated = false
      this.pin = null
      this.preset_not_found = false
      this.id = null
      localStorage.removeItem('billboard-view')

      this.data = {}

      throw error
    })
  }

  public async checkAuth(preload = false) {
    let keys: any = localStorage.getItem('billboard-view')
    keys = JSON.parse(keys)

    if (preload) {
      return await this.loadPresets()
    }
    if ((keys.code && keys.pin) || this.pin && this.code) {
      return await this.loadPresets()
    }
    this.is_authenticated = false
    return Promise.resolve()
  }
}
