
import { Component, Prop, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    Widget,
  },
})
export default class Scorecard extends ViewModel {
  @Prop()
  public data!: any

  public ready: boolean = false

  public mounted() {
    this.ready = true
  }
}
