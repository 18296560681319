
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component
export default class Welcome extends ViewModel {
  @Prop({ required: true })
  public data!: any

  @Watch('subType')
  public onSubTypeChange(newSubType: string) {
    // Delay is required as on type change, image might be hidden
    setTimeout(() => {
      if (newSubType === 'image-full') {
        this.addImageToElement()
      } else {
        this.removeImageFromElement()
      }
    }, 500)
  }

  public get subType() {
    if (!this.data.sub_type) {
      return this.data.image && this.data.image.length ? 'image-full' : ''
    }
    return this.data.sub_type
  }

  public mounted() {
    if (this.data.image && !this.data.sub_type) {
      this.addImageToElement()
    }
  }

  private get element() {
    return document.querySelector('.component-wrapper-billboard') as HTMLElement
  }

  public get randonNumber() {
    return Math.floor(Math.random() * 1000000)
  }

  public addImageToElement() {
    if (this.element && this.data.image) {
      document
        .getElementById('image-full')
        ?.setAttribute(
          'src',
          `${this.cdnPath}images/billboards/${this.data.image}?cb=${this.randonNumber}`,
        )
    }
  }

  public removeImageFromElement() {
    if (this.element) {
      this.element.style.backgroundImage = ''
      this.element.style.backgroundSize = ''
      this.element.style.backgroundRepeat = ''
      this.element.style.backgroundPosition = ''
    }
  }
}
